body {
  margin: 0;
  font-family: "Open Sans", sans-serif !important;
}

code {
  font-family: "Open Sans", sans-serif !important;
}

/* Navbar Css */
/* Hamburger icon */
.navbar-toggler {
  border: none;
  background: transparent;
}

.navbar-toggler .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0,0,0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}

/* Cross icon */
.navbar-toggler.toggled .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0,0,0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M8 8l16 16M24 8l-16 16'/%3E%3C/svg%3E");
}

.overlay-navbar {
  position: absolute;
  width: 100%;
  z-index: 1000; /* Ensure the navbar is on top of the carousel */
  padding: 1.5rem; /* Adjust padding as needed */
}

.navbar-overlay {
  top: 0; 
}

.brand-image-overlay {
  cursor: pointer;
  position: absolute;
  top: 50%;
  left: 9.5%;
  transform: translate(-50%, -50%);
}

.black-bold-text {
  font-weight: 600 !important;
  color: gray !important;
}

.black-font {
  color: black !important;
}

.navbar-toggler:not(:disabled):not(.disabled) {
  border: none;
}

.black-bold-text:hover {
  color: black !important;
}

.button-class {
  color: white !important;
  background-color: #006bb5 !important;
  border: none !important;
  font-size: 16px !important;
}

.nav-link:hover {
  color: #006bb5 !important;
}

.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .show > .nav-link {
  color: #006bb5 !important;
}

/* Caresoul Css */
.carousel-image {
  height: 650px;
}

.custom-caresoul .carousel-indicators {
  display: none !important;
}

/* Service Css */
.card-style {
  border: none !important;
}

/* Rope caresouls Css */
.rope-caresouls .carousel-control-prev,
.rope-caresouls .carousel-control-next {
  display: none !important;
}

.rope-caresouls .carousel-inner {
  height: 500px !important;
}

.industry-responsive {
  padding: 3rem;
}

/* Counter Css */
.custom-card {
  width: 18rem;
  border: none !important;
  text-align: center;
}

.custom-card-border {
  border-right: 1px solid #d0cccc !important; /* Add a vertical border on the right side */
  border-radius: 0% !important;
}

.choose-responsive {
  padding: 3rem !important;
}

.nets-responsive {
  padding-top: 3rem;
}

/* Contact-us Css */
.contact-responsive {
  padding: 3rem;
}

.form-label {
  color: #006bb5;
  font-weight: 600;
}

.custom-input {
  background-color: #e3eef0 !important;
  border-radius: 0% !important;
}

/* Footer Css */
.footer-brand{
  text-align: right;
  margin-top: 1rem;
  font-size: 12px;
}

.footer-bg {
  background-color: black;
  border-radius: 15px 15px 0px 0px;
}

.font-white {
  color: white;
}

.service-item.first {
  border-right: 1px solid #315481;
  border-bottom: 1px solid #315481;
}

.service-item.second {
  border-left: 1px solid #315481;
  border-right: 1px solid #315481;
  border-bottom: 1px solid #315481;
}

.cursor {
  cursor: pointer;
}

.industry-inner {
  padding: 3rem !important;
}

.choose-us-inner {
  padding: 3rem !important;
}

.width-50 {
  width: 50%;
}

.width-25 {
  width: 25%;
}

.width-100 {
  width: 100%;
}

.height-100 {
  height: 100%;
}

/* Product page css*/
.rope-responsive {
  .custom-caresoul {
    margin-top: 0rem !important;
  }
  .carousel-inner {
    height: 620px !important;
  }
}

.custom-container{
  .carousel-inner {
    border-radius: 10px !important;
  }
}

.product-carousel {
  .carousel-control-prev {
    display: none !important;
  }
  .carousel-control-next {
    display: none !important;
  }
}

.product-images {
  width: 600px; 
  height: 500px;
  object-fit: fill;
}

/* .product-image {
  height: 120px;
  width: 120px;
} */

.custom-container {
  max-height: 550px;
  overflow-y: auto;
}

.table-container {
  height: 520px; /* Adjust the height as needed */
}

.remove-padding {
  padding-left: 3rem !important;
  padding-right: 3rem !important;
}

.font-size-medium-nets {
  font-size: 22px;
}
/* Media query for medium screens */
@media (max-width: 576px) {
  .remove-padding {
    padding-left: 0rem !important;
    padding-right: 0rem !important;
  }
  .padding-bottom {
    padding-bottom: 0rem !important;
  }
  .width-50 {
    width: 100%;
  }

  .width-25 {
    width: 50%;
  }
  .carousel-image {
    height: 300px;
  }

  .carousel-control-prev {
    display: none !important;
  }

  .carousel-control-next {
    display: none !important;
  }

  .nav-button {
    width: 100px;
    height: 40px;
    font-size: 14px !important;
    margin-right: 23px;
    margin-top: 10px;
  }

  .overlay-navbar {
    .navbar-overlay {
      padding: 0.5rem !important;
    }
    .container {
      .navbar-brand {
        .brand-image-overlay {
          top: 50%;
          left: 18%;
          width: 80px !important;
          height: 80px !important;
        }
      }
    }
  }

  .carousel-image {
    height: 255px;
  }

  .service-responsive {
    .service-inner {
      padding: 0% !important;
      .card-style {
        width: 8rem !important;
        height: 11rem !important;
        .button-class {
          font-size: 12px !important;
        }
      }
    }
    .service-para {
      padding: 1.5rem 0rem 0rem 0rem !important;
      .service-details {
        padding: 0rem 0rem 0rem 0rem !important;
      }
    }
  }

  .industry-responsive {
    padding-bottom: 0rem !important;
    .fw-bold {
      font-size: 16px !important;
    }
    .industry-inner {
      margin-top: 2rem !important;
      padding: 0% !important;
      .card-text {
        margin-bottom: 0% !important;
      }
      .text-white {
        font-size: 12px !important;
      }
    }
    .industry-para {
      padding-bottom: 0rem !important;
    }
  }

  .counter-responsive {
    .counter-inner {
      .card-img {
        width: 50px !important;
        height: 50px !important;
      }
      h4 {
        font-size: 10px !important;
        font-weight: 600;
      }
      h1 {
        font-size: 18px !important;
      }
    }
    padding: 1rem 0rem 0rem 0rem !important;
  }

  .rope-caresouls .carousel-inner {
    height: 255px !important;
  }

  h3 {
    font-size: 16px !important;
  }

  p {
    font-size: 12px !important;
  }

  .choose-responsive {
    padding-top: 0rem !important;
    padding-bottom: 0rem !important;
    padding-left: 0rem !important;
    padding-right: 1rem !important;
  }
  .choose-us-inner {
    padding-bottom: 0rem !important;
  }

  .contact-responsive {
    padding: 1rem;
    .contact-form {
      margin-bottom: 2rem !important;
    }
  }

  .small-display {
    margin-top: 2rem !important;
  }

  .font-size-small {
    font-size: 12px !important;
  }
  .font-size-medium {
    font-size: 16px;
  }

  .remove-padding {
    text-align: center !important;
    padding-left: 0rem !important;
    padding-right: 0rem !important;
  }

  .custom-container {
    max-height: 350px;
    overflow-y: auto;
  }

  .table-container {
    height: 355px; /* Adjust the height as needed */
  }


  .product-images {
    width: 300px; 
    height: 300px;
    object-fit: fill; /* This will crop and zoom the image to fill the dimensions */
  }
}

@media (min-width: 577px) and (max-width: 768px) {

  .image-responsive{
    width: 200px;
    height: 200px;
  }
  .carousel-image {
    height: 455px;
  }

  .card-div {
    padding-bottom: 0rem !important;
  }

  .nav-button {
    width: 120px;
    height: 40px;
    font-size: 16px !important;
    margin-right: 23px;
    margin-top: 10px;
  }

  .overlay-navbar {
    .navbar-overlay {
      padding: 0.5rem !important;
    }
    .container {
      .navbar-brand {
        .brand-image-overlay {
          top: 50%;
          left: 12%;
          width: 80px !important;
          height: 80px !important;
        }
      }
    }
  }

  .service-responsive {
    .service-inner {
      padding: 0% !important;
      .card-style {
        width: 12rem !important;
        height: 11rem !important;
        .button-class {
          font-size: 14px !important;
        }
      }
    }
    .service-para {
      padding: 1.5rem 0rem 0rem 0rem !important;
      .service-details {
        padding: 0rem 0rem 0rem 0rem !important;
      }
    }
  }

  .industry-responsive {
    padding-bottom: 0rem !important;
    padding-top: 0rem !important;
    .fw-bold {
      font-size: 18px !important;
    }
    .industry-inner {
      margin-top: 2rem !important;
      padding: 0% !important;
    }
    .industry-para {
      padding-bottom: 0rem !important;
    }
  }

  .card-responsive {
    .card-title {
      font-size: 12px !important;
    }
    .card-text {
      font-size: 10px !important;
      margin-bottom: 0rem !important;
    }
    .text-white {
      font-size: 10px !important;
    }
  }

  .counter-responsive {
    padding: 1rem 0rem 0rem 0rem !important;
  }

  .choose-responsive {
    padding-top: 0rem !important;
    padding-bottom: 0rem !important;
    padding-left: 0rem !important;
    padding-right: 1rem !important;
  }
  .choose-us-inner {
    padding-bottom: 0rem !important;
  }

  .contact-responsive {
    padding: 1rem;
    .contact-form {
      margin-bottom: 2rem !important;
    }
  }

  .rope-responsive {
    .custom-caresoul {
      margin-top: 0rem !important;
    }
    .carousel-inner {
      height: 400px !important;
    }
  }

  .custom-container {
    max-height: 350px;
    overflow-y: auto;
  }

  .table-container {
    height: 275px; /* Adjust the height as needed */
  }
  .font-size-small {
    font-size: 12px !important;
  }
  .font-size-medium {
    font-size: 16px;
  }

  .product-images {
    width: 289px; 
    height: 280px;
    object-fit: fill; /* This will crop and zoom the image to fill the dimensions */
  }
}

/* Media query for large screens */
@media (min-width: 769px) and (max-width: 992px) {
  .image-responsive{
    width: 200px;
    height: 200px;
  }
  .overlay-navbar {
    .container {
      .navbar-brand {
        .brand-image-overlay {
          top: 50%;
          left: 9%;
          width: 110px !important;
          height: 110px !important;
        }
      }
    }
  }

  .carousel-image {
    height: 560px;
  }

  .service-responsive {
    .service-inner {
      padding: 0% !important;
      .card-style {
        width: 15rem !important;
        height: 11rem !important;
        .button-class {
          font-size: 14px !important;
        }
      }
    }
  }

  .industry-responsive {
    padding-bottom: 0rem !important;
    .fw-bold {
      font-size: 18px !important;
    }
    .industry-inner {
      margin-top: 2rem !important;
      padding: 0% !important;
    }
    .industry-para {
      padding-bottom: 0rem !important;
    }
  }

  .choose-responsive {
    padding-top: 0rem !important;
    padding-bottom: 0rem !important;
  }
  .choose-us-inner {
    padding-bottom: 0rem !important;
  }

  .card-responsive {
    .card-title {
      font-size: 16px !important;
    }
    .card-text {
      font-size: 14px !important;
    }
    .text-white {
      font-size: 12px !important;
    }
  }

  .rope-responsive {
    .custom-caresoul {
      margin-top: 0rem !important;
    }
    .carousel-inner {
      height: 500px !important;
    }
  }

  .custom-container {
    max-height: 450px;
    overflow-y: auto;
  }

  .table-container {
    max-height: 385px; /* Adjust the height as needed */
  }

  .product-images {
    width: 408px; 
    height: 350px;
    object-fit: fill; /* This will crop and zoom the image to fill the dimensions */
  }
}

/* Media query for extra large screens */
@media (min-width: 993px) and (max-width: 1200px) {
  .carousel-image {
    height: 500px;
  }

  .service-responsive {
    .service-inner {
      padding: 0% !important;
      .card-style {
        width: 8rem !important;
        height: 11rem !important;
        .button-class {
          font-size: 13px !important;
        }
      }
    }
  }

  .industry-responsive {
    padding-bottom: 0rem !important;
    .fw-bold {
      font-size: 18px !important;
    }
    .industry-inner {
      margin-top: 2rem !important;
      padding: 0% !important;
    }
    .industry-para {
      padding-bottom: 0rem !important;
    }
  }

  .choose-responsive {
    padding-top: 0rem !important;
    padding-bottom: 0rem !important;
  }
  .choose-us-inner {
    padding-bottom: 0rem !important;
  }

  .custom-container {
    max-height: 450px;
    overflow-y: auto;
  }

  .table-container {
    max-height: 420px; /* Adjust the height as needed */
  }

  .width-100 {
    width: 90%;
  }

  .height-100 {
    height: 90%;
  }

  .rope-responsive {
    .custom-caresoul {
      margin-top: 0rem !important;
    }
    .carousel-inner {
      height: 500px !important;
    }
  }

  .product-images {
    width: 500px; 
    height: 400px;
    object-fit: fill; /* This will crop and zoom the image to fill the dimensions */
  }
  
}
